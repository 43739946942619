import { SPECIAL_TABLE_TYPES } from 'state-domains/constants';

export const i18n: Record<string, any> = {
    name: {
        id: 'configuration-filter.name',
        defaultMessage: 'Name',
    },
    description: {
        id: 'configuration-filter.description',
        defaultMessage: 'Description',
    },
    category: {
        id: 'configuration-filter.category',
        defaultMessage: 'Category',
    },
    source: {
        id: 'configuration-filter.soruce',
        defaultMessage: 'Source',
    },
    sourceMx: {
        id: 'configuration-filter.sourceMx',
        defaultMessage: 'MX',
    },
    sourceSite: {
        id: 'configuration-filter.sourceSite',
        defaultMessage: 'Site',
    },
    usedIn: {
        id: 'configuration-filter.usedIn',
        defaultMessage: 'Used in',
    },
    label: {
        id: 'configuration-filter.label',
        defaultMessage: 'Label',
    },
    tableType: {
        id: 'configuration-filter.tableType',
        defaultMessage: 'Table type',
    },
    tableTypeInterval: {
        id: 'configuration-filter.tableTypeInterval',
        defaultMessage: 'Interval',
    },
    tableTypeDepth: {
        id: 'configuration-filter.tableTypeDepth',
        defaultMessage: 'Depth',
    },
    tableTypeData: {
        id: 'configuration-filter.tableTypeData',
        defaultMessage: 'Data',
    },
    linkedToLithology: {
        id: 'configuration-filter.linkedToLithology',
        defaultMessage: 'Linked to lithology',
    },
    isLinkedToLithology: {
        id: 'configuration-filter.isLinkedToLithology',
        defaultMessage: 'Is linked to lithology',
    },
    isNotLinkedToLithology: {
        id: 'configuration-filter.isNotLinkedToLithology',
        defaultMessage: 'Is not linked to lithology',
    },
    gaps: {
        id: 'configuration-filter.gaps',
        defaultMessage: 'Gaps',
    },
    allowGaps: {
        id: 'configuration-filter.allowGaps',
        defaultMessage: 'Allow gaps ',
    },
    notAllowGaps: {
        id: 'configuration-filter.notAllowGaps',
        defaultMessage: 'Do not allow gaps',
    },
    overlaps: {
        id: 'configuration-filter.overlaps',
        defaultMessage: 'Overlaps',
    },
    allowOverlaps: {
        id: 'configuration-filter.allowOverlaps',
        defaultMessage: 'Allow overlaps ',
    },
    notAllowOverlaps: {
        id: 'configuration-filter.notAllowOverlaps',
        defaultMessage: 'Do not allow overlaps',
    },
    duplicates: {
        id: 'configuration-filter.duplicates',
        defaultMessage: 'Duplicates',
    },
    allowDuplicates: {
        id: 'configuration-filter.allowDuplicates',
        defaultMessage: 'Allow duplicates ',
    },
    notAllowDuplicates: {
        id: 'configuration-filter.notAllowDuplicates',
        defaultMessage: 'Do not allow duplicates',
    },
    tableOrTableView: {
        id: 'configuration-filter.tableOrTableView',
        defaultMessage: 'Table or table view',
    },
    childTable: {
        id: 'configuration-filter.childTable',
        defaultMessage: 'Child table',
    },
    isChildTable: {
        id: 'configuration-filter.isChildTable',
        defaultMessage: 'Is child table',
    },
    isNotChildTable: {
        id: 'configuration-filter.isNotChildTable',
        defaultMessage: 'Is not child table',
    },
    table: {
        id: 'configuration-filter.table',
        defaultMessage: 'Table',
    },
    tableView: {
        id: 'configuration-filter.tableView',
        defaultMessage: 'Table view',
    },
    headerType: {
        id: 'configuration-filter.headerType',
        defaultMessage: 'Header type',
    },
    drilling: {
        id: 'configuration-filter.drilling',
        defaultMessage: 'Drill Hole',
    },
    point: {
        id: 'configuration-filter.point',
        defaultMessage: 'Point',
    },
    dataType: {
        id: 'configuration-filter.dataType',
        defaultMessage: 'Data type',
    },
    text: {
        id: 'configuration-filter.text',
        defaultMessage: 'Text',
    },
    numeric: {
        id: 'configuration-filter.numeric',
        defaultMessage: 'Numeric',
    },
    checkbox: {
        id: 'configuration-filter.checkbox',
        defaultMessage: 'Checkbox',
    },
    file: {
        id: 'configuration-filter.file',
        defaultMessage: 'File',
    },
    list: {
        id: 'configuration-filter.list',
        defaultMessage: 'List',
    },
    date: {
        id: 'configuration-filter.date',
        defaultMessage: 'Date',
    },
    type: {
        id: 'configuration-filter.type',
        defaultMessage: 'Type',
    },
    filter: {
        id: 'configuration-filter.filterLists',
        defaultMessage: 'Filter',
    },
    assignedTables: {
        id: 'configuration-filter.assignedTables',
        defaultMessage: 'Assigned tables',
    },
    assignedHeaders: {
        id: 'configuration-filter.assignedHeaders',
        defaultMessage: 'Assigned headers',
    },
    assignedActivities: {
        id: 'configuration-filter.assignedActivities',
        defaultMessage: 'Assigned activities',
    },
    assignedUsers: {
        id: 'configuration-filter.assignedUsers',
        defaultMessage: 'Assigned users',
    },
    isGeographic: {
        id: 'configuration-filter.isGeographic',
        defaultMessage: 'Is Geographic',
    },
    geographic: {
        id: 'configuration-filter.geographic',
        defaultMessage: 'Geographic',
    },
    projection: {
        id: 'configuration-filter.projection',
        defaultMessage: 'Projection',
    },
    world: {
        id: 'configuration-filter.world',
        defaultMessage: 'World',
    },
    custom: {
        id: 'configuration-filter.custom',
        defaultMessage: 'Custom',
    },
    module: {
        id: 'configuration-filter.module',
        defaultMessage: 'Module',
    },
    specialTable: {
        id: 'configuration-special.table',
        defaultMessage: 'Special Table',
    },
    [SPECIAL_TABLE_TYPES.LITHOLOGY]: {
        id: 'configuration-lithology',
        defaultMessage: 'Lithology',
    },
    [SPECIAL_TABLE_TYPES.SAMPLES]: {
        id: 'configuration-samples',
        defaultMessage: 'Samples',
    },
    [SPECIAL_TABLE_TYPES.STRUCTURE]: {
        id: 'configuration-structure',
        defaultMessage: 'Structure',
    },
    [SPECIAL_TABLE_TYPES.SURVEY]: {
        id: 'configuration-survey',
        defaultMessage: 'Survey',
    },
    [SPECIAL_TABLE_TYPES.XRF]: {
        id: 'configuration-xrf',
        defaultMessage: 'XRF',
    },
    [SPECIAL_TABLE_TYPES.OTHER]: {
        id: 'configuration-other',
        defaultMessage: 'Other',
    },
    hasFiles: {
        id: 'configuration-has-files',
        defaultMessage: 'Has Files',
    },
    doesntHaveFiles: {
        id: 'configuration-doesnt-have-files',
        defaultMessage: 'Does Not Have Files',
    },
};
